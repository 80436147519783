import { useState } from "react";
import AxiosInstance from "../Axios";
import { CButton } from '@coreui/react';
import { Link } from "react-router-dom";



const urlApi = '/order_products';





export const CreateFromStorageElements = ({storages, id, setGetOP}) => {
    const [newOrdersData, setNewOrdersData] = useState(false);
    const syncedOrder = () => {
        AxiosInstance.post(`orders/synced`, { order_id: id }).then((response) => {
            setGetOP(true);
            setNewOrdersData(response.data);
            return response;
        });
    }

    return (
        <>
            <p>В замовленні є товари з таких складів:</p>
            <ul>
                {Object.keys(storages).map((k) => {
                    return(
                        <li>{storages[k].name} (к-ть - {storages[k].orderProducts.length})</li>
                    )
                })}
                
            </ul>
            {
                (!newOrdersData) ?
                    <>
                        <p>Щоб за синхронізувати в 1с , автоматично створяться {Object.keys(storages).length} замовлення відповідно до складів.</p>
                        <CButton color='secondary' onClick={() =>syncedOrder()}>Синхронізувати в 1с</CButton>
                    </>
                :
                    <>
                        <p><strong>Замовлення розбито на:</strong></p>
                        <ul>
                            {
                                newOrdersData.map((value) => {
                                    return <li><Link to={`/orders/update/${value.order_id}`} target="_blank">#{value.order_id}</Link> - {value.storage_name}</li>
                                })
                            }
                        </ul>
                    </>
            }
        </>
    );
};



export async function GetStorages(id, setName) {
    try {
      const response = await AxiosInstance.get(`${urlApi}?order=${id}`, {
        headers: { 'accept': 'application/json' }
      });
  
      const data = response.data;
      let storages = {};
      data.forEach((orderProduct) => {
        let st = orderProduct.product.storageElement.storage;
        if (st !== undefined) {
            if (storages[st.id] === undefined) {
            storages[st.id] = {
                id: st.id,
                name: st.name,
                orderProducts: []
            };
            } 
            storages[st.id]['orderProducts'].push(orderProduct);
          
        }
      });
  
      return storages;
    } catch (error) {
      console.error("Помилка при отриманні даних", error);
      throw error; // Передаємо помилку далі
    }
}

