import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CForm, CInputGroup, CFormLabel, CFormInput, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { AiOutlineEye } from "react-icons/ai";

const urlApi = '/products';
const urlRouter = '/products';

const page = 1;
const itemsPerPage = 100;

export async function LoaderProducts({ params }) {
  let res = await AxiosInstance.get(`${urlApi}?page=${page}&itemsPerPage=${itemsPerPage}`).then((response) => {
    return response;
  });
  return res;
};

const Products = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [nameFilterSearch, setNameFilterSearch] = useState('');
  const [code1cFilterSearch, setCode1cFilterSearch] = useState('');
  const [barcodeFilterSearch, setBarcodeFilterSearch] = useState('');
  const [articleFilterSearch, setArticleFilterSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);

  if (!rows) {
    setRowCountState(data['hydra:totalItems']);
    setRows(data['hydra:member']);
  };

  // Запит за продуктами (+фільтр)
  const getRowsTable = (p = 1) => {
    let request = `&itemsPerPage=${itemsPerPage}`;
    if (nameFilterSearch != '') {
      request += `&name=${nameFilterSearch}`;
    };
    if (code1cFilterSearch != '') {
      request += `&code1c=${code1cFilterSearch}`;
    };
    if (barcodeFilterSearch != '') {
      request += `&barcode=${barcodeFilterSearch}`;
    };
    if (articleFilterSearch != '') {
      request += `&article=${articleFilterSearch}`;
    };
    // console.log("request", request);
    AxiosInstance.get(`${urlApi}?page=${p}${request}`).then((response) => {
      setRows(response.data['hydra:member']);
      setRowCountState(response.data['hydra:totalItems']);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const disabledButton = (event) => {
    if (event.target.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (nameFilterSearch != "" || code1cFilterSearch != "" || barcodeFilterSearch != "" || articleFilterSearch != "") {
      setDisabled(false);
    };
  }, [nameFilterSearch, code1cFilterSearch, barcodeFilterSearch, articleFilterSearch]);

  // ----- Start handleFilterChange -----
  const handleNameFilterChange = (event) => {
    setNameFilterSearch(event.target.value);
    disabledButton(event);
  };

  const handleCode1cFilterChange = (event) => {
    setCode1cFilterSearch(event.target.value);
    disabledButton(event);
  }

  const handleBarcodeFilterChange = (event) => {
    setBarcodeFilterSearch(event.target.value);
    disabledButton(event);
  };

  const handleArticleFilterChange = (event) => {
    setArticleFilterSearch(event.target.value);
    disabledButton(event);
  };
  // ----- End handleFilterChange -----

  // Фільтрування по name, code1c та barcode
  const handleFilterSearch = (event) => {
    // console.log("handleFilterSearch event", event)
    event.preventDefault();
    getRowsTable();
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setNameFilterSearch("");
    setCode1cFilterSearch("");
    setBarcodeFilterSearch("");
    setArticleFilterSearch("");
    setDisabled(true);
    getRowsTable();
  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(`${urlApi}/${row.id}`).then((response) => {
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'image',
      headerName: 'Image',
      width: 75,

      editable: true,
      renderCell: (params) => <img src={(params.row.media != undefined && params.row.media.length > 0) ? process.env.REACT_APP_SERVER_URL + '' + params.row.media[0].contentUrl : ''} style={{ height: '45px', width: 'auto' }} />
    },
    {
      field: 'name', headerName: 'Назва', width: 300,
      renderCell: (params) => {
        return (
          <Link
            to={'/products/read/' + params.row.id} title="Перейти до перегляду товару"
            style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}
          >
            {params.row.name}
          </Link>
        );
      }
    },
    { field: 'description', headerName: 'Опис', width: 150 },
    { field: 'article', headerName: 'article', width: 70 },
    { field: 'code1c', headerName: 'code1c', width: 120 },
    { field: 'barcode', headerName: 'Штрихкод', width: 150 },
    { field: 'status', headerName: 'Статус', width: 150 },
    { field: 'price', headerName: 'Ціна', width: 150 },
    { field: 'discounts', headerName: 'Знижки', width: 150 },
    { field: 'top', headerName: 'top', width: 150 },
    { field: 'nds', headerName: 'nds', width: 150 },
    { field: 'packaging', headerName: 'packaging', width: 150 },
    { field: 'productToOrder', headerName: 'Під замовлення', width: 150 },
    {
      field: "action_1",
      headerName: "Перегляд",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Link to={`${urlRouter}/read/${params.row.id}`} title="Перейти до перегляду товару">
            <CButton color="dark" variant="outline">
              <AiOutlineEye size={20} />
            </CButton>
          </Link>
        );
      }
    },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CAccordion flush>
            <CAccordionItem>
              <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
              <CAccordionBody>
                <CRow className="my-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      <CCol md={3} className='filter-field'>
                        <CFormInput
                          placeholder="Назва" id="nameFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      <CCol md={3} className='filter-field'>
                        <CFormInput
                          placeholder="Артикул" id="articleFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleArticleFilterChange} value={articleFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      <CCol md={3} className='filter-field'>
                        <CFormInput
                          placeholder="code1c" id="code1cFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleCode1cFilterChange} value={code1cFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      <CCol md={3} className='filter-field'>
                        <CFormInput
                          placeholder="Штрих-код" id="barcodeFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleBarcodeFilterChange} value={barcodeFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol md={3} style={{ textAlign: "end" }}>
                    <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>

          <CCardHeader>
            <strong>Товари</strong> <small>затягуються з 1с автоматично</small>
          </CCardHeader>
          <CCardBody>
            {/* <p className="text-medium-emphasis small">
              <Link to="/products/create"><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
            </p> */}
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={itemsPerPage}
                rowsPerPageOptions={[itemsPerPage]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Products;
