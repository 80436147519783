import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { cilPen, cilPlus, cilTrash, cilBasket } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { roundTheNumber } from 'src/components/store/roundTheNumber';


// Табиця з товарами, які додані до замовлення для orders/update
const ViewOrdersMain = (props) => {
    console.log("props ViewMain", props);
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><strong>#</strong></TableCell>
                            <TableCell align="center"><strong>Зображення</strong></TableCell>
                            <TableCell align="center"><strong>Артикул</strong></TableCell>
                            <TableCell align="center"><strong>Назва</strong></TableCell>
                            <TableCell align="center">
                                <div className='orders-ViewMain__TC'>
                                    <strong>Ціна</strong>
                                    <strong>націнка</strong>
                                    <strong>собівартість</strong>
                                </div>
                            </TableCell>
                            <TableCell align="center"><strong>Кількість</strong></TableCell>
                            <TableCell align="center"><strong>Одиниці Вим.</strong></TableCell>
                            <TableCell align="center"><strong>Сума</strong></TableCell>
                            <TableCell align="center"><strong>Знижка</strong></TableCell>
                            <TableCell align="center"><strong>Видалити</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (props.products.length > 0) ?
                                props.products.map((value, key) => {
                                    console.log("value", value);
                                    let i = ++key;
                                    let imgUrl = (value.product.media != undefined && value.product.media.length > 0) ? `${process.env.REACT_APP_SERVER_URL}${value.product.media[0].contentUrl}` : '/product.jpg';
                                    // Ціна заштуку
                                    const price = (value.priceForOne != undefined) ? value.priceForOne : 0;
                                    // Собівартість за штуку
                                    const priceIncome = (value.priceIncome != undefined) ? value.priceIncome : 0;
                                    // Націнка за товар
                                    const productMarkUp = (priceIncome == 0) ? 0 : (price - priceIncome);
                                    // Сума
                                    const sum = price * value.count;
                                    return (
                                        <TableRow key={value.id}>
                                            <TableCell align="center"><strong>{i}</strong></TableCell>
                                            <TableCell align="center">
                                                <img src={imgUrl} alt="blog-image" style={{ width: "50px" }} />
                                            </TableCell>
                                            <TableCell align="center">{value.product.article}</TableCell>
                                            <TableCell align="center">
                                                {
                                                    props.isOrderIn1c ?
                                                        // Замовлення вже в 1С
                                                        <strong>{value.product.name}</strong>
                                                        :
                                                        // Замовлення ще не внесено в 1С
                                                        <strong className="orders__linkToOrderProduct" onClick={() => props.handleOpen(value)}>{value.product.name}</strong>
                                                }
                                                <p className='text-muted'><strong>Склад: </strong>{(value.product.storageElement != undefined) ? value.product.storageElement.storage.name : '...'}</p>
                                            </TableCell>
                                            <TableCell align="center">
                                                <div className='orders-ViewMain__TC'>
                                                    <strong>{roundTheNumber(price)}</strong>
                                                    <strong>{roundTheNumber(productMarkUp)}</strong>
                                                    <strong>{roundTheNumber(priceIncome)}</strong>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center"><strong>{value.count}</strong></TableCell>
                                            <TableCell align="center"><strong>{value.measurementUnit.short_name}</strong></TableCell>
                                            <TableCell align="center"><strong>{roundTheNumber(sum)}</strong></TableCell>
                                            <TableCell align="center"><strong>{value.discount}</strong></TableCell>
                                            <TableCell align="center">
                                                <CIcon icon={cilTrash} customClassName="nav-icon" height={18} onClick={() => props.deleteProduct(value.id)} style={{ cursor: "pointer" }} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow><TableCell></TableCell></TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer >
        </>
    );
};

export default ViewOrdersMain;