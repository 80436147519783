import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionHeader, CAccordionBody, CAccordionItem } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash, cilClone, cilReload, cilArrowRight } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { GiShoppingCart } from 'react-icons/gi';
import { GrEdit } from 'react-icons/gr';
import CreateOrderFromPreOrder from 'src/components/orders/CreateOrderFromPreOrder';

const page = 1;
const postPerPage = 0;
const pageSize = 20;

const urlApi = '/pre_orders';
const urlRouter = '/pre-orders';
const urlRouter_2 = '/orders';

const role = localStorage.getItem('user_role');
const user_data = JSON.parse(localStorage.getItem('user_data'));
const user_id = (user_data != null && user_data != undefined && user_data.id != undefined) ? user_data.id : false;
const defaultUserFilter = (role == 'ROLE_MANAGER' && user_id) ? `&account.manager=${user_id}` : '';

export async function LoaderPreOrders({ params }) {
  let res = await AxiosInstance.get(urlApi + '?page=' + page + defaultUserFilter).then((response) => {
    return response;
  });
  return res;
}

const PreOrders = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [code1cFilterSearch, setCode1cFilterSearch] = useState('');
  const [accountsFilterSearch, setAccountsFilterSearch] = useState('');
  const [managerFilterSearch, setManagerFilterSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);

  // Для створення замовлення на основі preOrder
  const createOrder = CreateOrderFromPreOrder();

  if (!rows) {
    setRowCountState(data['hydra:totalItems'])
    setRows(data['hydra:member']);
  }

  // Запит за попередніми замовленнями (+фільтр)
  const getRowsTable = (p = 1) => {
    let request = ``;
    if (code1cFilterSearch != '') {
      request += `&code1c=${code1cFilterSearch}`;
    };
    if (accountsFilterSearch != '') {
      request += `&account.name=${accountsFilterSearch}`;
    };
    if (managerFilterSearch != '') {
      request += `&manager.firstName=${managerFilterSearch}`;
    };
    // console.log("request", request);
    AxiosInstance.get(`${urlApi}?page=${p}${request}` + defaultUserFilter).then((response) => {
      setRows(response.data['hydra:member']);
      setRowCountState(response.data['hydra:totalItems']);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Заблокувати кнопку фільтрації, якщо не введені символи для пошуку
  const disabledButton = event => {
    if (event.target.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    };
  };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (code1cFilterSearch != "" || accountsFilterSearch != "" || managerFilterSearch != "") {
      setDisabled(false);
    };
  }, [code1cFilterSearch, accountsFilterSearch, managerFilterSearch]);

  // ----- Start handleFilterChange -----
  const handleCode1cFilterChange = event => {
    setCode1cFilterSearch(event.target.value);
    disabledButton(event);
  };

  const handleAccountsFilterChange = event => {
    setAccountsFilterSearch(event.target.value);
    disabledButton(event);
  };

  const handleManagerFilterChange = event => {
    setManagerFilterSearch(event.target.value);
    disabledButton(event);
  };
  // ----- End handleFilterChange -----

  // Фільтрування по code1c, accounts та manager
  const handleFilterSearch = () => {
    getRowsTable();
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setCode1cFilterSearch("");
    setAccountsFilterSearch("");
    setManagerFilterSearch("");
    setDisabled(true);
    getRowsTable();
  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(urlApi + '/' + row.id, header).then((response) => {
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const columns = [
    {
      field: 'id',
      headerName: '#',
      width: 70,
      renderCell: (params) => {
        return <Link to={urlRouter + '/update/' + params.row.id} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>{params.row.id}</Link>;
      }
    },
    {
      field: 'code1c',
      headerName: 'Код 1с',
      width: 120,
      renderCell: (params) => {
        return <Link to={urlRouter + '/update/' + params.row.id} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>{params.row.code1c}</Link>;
      }
    },
    {
      field: 'accounts',
      headerName: 'Контрагент',
      width: 200,
      renderCell: (params) => {
        return (params.row.account != undefined) ? params.row.account.name : '';
      }
    },
    {
      field: 'dateEntered',
      headerName: 'Дата',
      type: 'date',
      width: 160,
    },
    {
      field: 'manager',
      headerName: 'Менеджер',
      width: 200,
      renderCell: (params) => {
        return (params.row.manager != undefined) ? `${params.row.manager.firstName} ${params.row.manager.lastName}` : '';
      }
    },
    {
      field: 'sumOrdered',
      headerName: 'Сума',
      width: 120,
      renderCell: (params) => {
        return (params.row.sumOrdered != undefined) ? `${params.row.sumOrdered} ${(params.row.curency != undefined) ? params.row.curency : "uah"}` : '';
      }
    },
    {
      field: 'countProd',
      headerName: 'К-сть тов.',
      width: 60,
      renderCell: (params) => {
        return (params.row.orderProducts != undefined) ? params.row.orderProducts.length : '';
      }
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return <Link to={urlRouter + '/update/' + params.row.id}><CButton color="dark" variant="outline" title="Редагувати"><GrEdit className="nav-icon" /></CButton></Link>;
      }
    },
    {
      field: "create",
      headerName: "Реалізувати",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {
              (params.row.orders != undefined && params.row.orders.length > 0) ?
                // "Реалізовано"
                <Link to={urlRouter_2 + '/update/' + params.row.orders[0].id}>
                  <CButton color="dark" variant="outline" title="Реалізовано. Перейти до замовлення">
                    <CIcon icon={cilArrowRight} customClassName="nav-icon" height={15} />
                  </CButton>
                </Link>
                :
                // "Реалізувати"
                <CButton color="dark" variant="outline" title="Реалізувати"
                  onClick={() => {
                    createOrder.setOrder(params.row);
                  }}>
                  <CIcon icon={cilReload} customClassName="nav-icon" height={15} />
                </CButton>
            }
          </>
        )
      }
    },
    // {
    //   field: "delete",
    //   headerName: "Видалити",
    //   sortable: false,
    //   renderCell: (params) => {
    //     return <CButton color="danger" variant="outline" onClick={() => {
    //         Deleted(params.row)
    //       }
    //     }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
    //   }
    // },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CAccordion flush>
            <CAccordionItem>
              <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
              <CAccordionBody>
                <CRow className="my-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      {/* <CCol md={4} className='filter-field'> */}
                      <CCol md={4}>
                        <CFormInput
                          placeholder="Код 1с" id="code1cFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleCode1cFilterChange} value={code1cFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      <CCol md={4} className='filter-field'>
                        <CFormInput
                          placeholder="Контрагент" id="accountsFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleAccountsFilterChange} value={accountsFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      <CCol md={4} className='filter-field'>
                        <CFormInput
                          placeholder="Менеджер" id="magerFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleManagerFilterChange} value={managerFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                    </CRow>

                  </CCol>
                  <CCol md={3} style={{ textAlign: "end" }}>
                    <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>

          <CCardHeader><GiShoppingCart className="nav-icon" />
            <strong>Попереднє замовлення клієнтів</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <p className="text-medium-emphasis small">
              {/* <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height="15" />Створити</CButton></Link> */}
            </p>
            <div style={{ height: 400, width: '100%' }}>

              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default PreOrders;
