import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import {Link, useLoaderData} from 'react-router-dom';
import axios from "axios";
import AddProduct from 'src/components/product/AddProduct';
import AxiosInstance from 'src/components/Axios';

const urlApi = '/site_products';
const urlRouter = '/products';

export async function LoaderSiteProductsUpdate({ params }) {
  if(Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      console.log(response)
      return response;
    });
    return res;
  }
  return  false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const SiteProductForm = () => {
  const {data}  = useLoaderData();
  const [name, setName] = useState((data != undefined)? data.name : '');
  const [status, setStatus] = useState((data != undefined)? data.status : '');
  const [price, setPrice] = useState((data != undefined)? data.price : '');
  // const [mainImg, setmainImg] = useState((data != undefined)? data.images : '');
  const editorRef = useRef(null);

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
      const article = {
        name: name,
        status: status,
        price: parseFloat( price ),
        description: editorRef.current.getContent(),
        
      };
      if (data != undefined){
        const response = AxiosInstance.put(`${urlApi}/${data.id}`, article);
      }else {
        const response = AxiosInstance.post(urlApi, article);
      }
    }
  }


    return (
      <>
        <CForm onSubmit={handleSubmit}>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Назва Товару</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Статус</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput2" value={status} onChange={(e) => setStatus(e.target.value)} />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Ціна</CFormLabel>
            <CFormInput type="number" id="exampleFormControlInput2" value={price} onChange={(e) => setPrice(e.target.value)} />
          </div>
          <div className="mb-3">
            <Editor
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={(data != undefined)? data.description : ''}
              init={{
                height: 200,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
            />
          </div>
          <CButton type="submit" color="success">Зберегти</CButton>
        </CForm>

        { (data != undefined) ? <AddProduct products={data.products} id={data.id}/> : '' }
      </>
    );
};

export default SiteProductForm;
