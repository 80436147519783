import { useState, useEffect } from "react";
import { cilSave } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
import { Carousel } from 'react-responsive-carousel';

// Оновити конкретний продукт, який вже доданий до замовлення
const UpdateProductItem = (props) => {
    // console.log("UpdateProductItem props", props);

    const urlApi = '/order_products';
    const [counter, setCounter] = useState(props.product.count);
    // Зображення товару
    const media = (props.product.product.media != undefined && props.product.product.media.length > 0) ? props.product.product.media : "";
    const code1c = (props.product != undefined && props.product.product.code1c != undefined) ? props.product.product.code1c : "";
    const barcode = (props.product != undefined && props.product.product.barcode != undefined) ? props.product.product.barcode : "";
    const article = (props.product != undefined && props.product.product.article != undefined) ? props.product.product.article : "";
    const measurementUnit = (props.product != undefined && props.product.product.measurement_unit != undefined) ? props.product.product.measurement_unit.short_name : "";
    // Ціна одиниці товару
    const productPrice = (props.product != undefined && props.product.priceForOne != undefined) ? props.product.priceForOne : "0";
    // Ціна введена менеджером
    const [priceManager, setPriceManager] = useState(productPrice);
    // Вхідна ціна одиниці товару
    const productIncomePrice = (props.product != undefined && props.product.priceIncome != undefined) ? props.product.priceIncome : "0";
    // Змінені дані про товар, які будуть додані до замовлення
    const [productData, setProductData] = useState(false);
    // Сума замовлення по товару
    const sum = priceManager * (isNaN(counter) ? 0 : counter);
    // Заблокувати кнопку "Додати до замовлення"
    const [disabledBtn, setDisabledBtn] = useState(false);
    // Текст для popup
    const popupTextSuccess_1 = "Дані замовленого товару оновлено";
    const popupTextSuccess_2 = "Відкрито вікно редагування замовлення по товару";
    const popupTextSuccess_3 = "Вікно редагування замовлення по товару закрито";

    // Вивести повідомлення коли відкривається вікно з інформацією про товар
    useEffect(() => {
        // Popup про успішні зміни
        props.toastifySuccess(popupTextSuccess_2);
    }, [props.product.id]);

    // Збільшити кількість на 1
    function productIncrement() {
        setCounter(counter + 1);
    };
    // Зменшити кількість на 1
    function productDecrement() {
        if (counter > 2) {
            setCounter(counter - 1);
        }
        else {
            setCounter(1);
        }
    };

    // Зміна кількості шт. продуктів вручну
    const inputChange = (event) => {
        // console.log("inputChange event", event.target.value);
        // typeof event.target.value = string, перевести в тип number

        // Перетворити з типу string в тип number
        let value = parseFloat(event.target.value);
        let count = (value <= 0) ? 1 : value;
        setCounter(count);
    };

    // Перевірка counter на NaN
    useEffect(() => {
        // console.log('counter', counter);
        if (isNaN(counter)) {
            setDisabledBtn(true);
        } else {
            setDisabledBtn(false);
        };
    }, [counter]);

    // Функція для кількості (Зміна кількості шт. продуктів вручну)
    const notEmpty = (event) => {
        if (event.target.value === "") {
            setCounter(1);
        } else {
            return;
        };
    };

    // Зміна ціни продукту вручну менеджером
    const priceChange = (event) => {
        // console.log("priceChange event", event.target.value);
        // typeof event.target.value = string, перевести в тип number
        let price = (event.target.value === "" || event.target.value < 0) ? 1 : parseFloat(event.target.value);
        setPriceManager(price);
    };

    // Формування даних про товар для додавання до замовлення
    useEffect(() => {
        setProductData({
            "count": counter,
            "priceForOne": priceManager,
            "sumOrdered": sum
        });
    }, [counter, priceManager]);

    // Запит на API для оновлення даних про замовлений товар
    const putProductData = () => {
        AxiosInstance.put(`${urlApi}/${props.product.id}`, productData).then((response) => {
            if (response.status === 200) {
                // console.log("response", response);
                // alert("Дані оновлено");
                // Popup про успішні зміни
                props.toastifySuccess(popupTextSuccess_1);
                // Показати таблицю з доданими до замовлення товарами
                props.setShowTable(true);
                // Для запиту за списком з доданими товарами до замовлення
                props.setGetOP(true);
                // Для можливого повторного відкриття вікна редагування товару
                props.setOpenUpdateProduct(false);
            };
        });
    };

    // Функція для закриття оновлення товару і відкриття модального вікна з списком доданих товарів
    const changeModal = () => {
        // Показати таблицю з доданими до замовлення товарами
        props.setShowTable(true);
        // Для запиту за списком з доданими товарами до замовлення
        props.setGetOP(true);
        // Для можливого повторного відкриття вікна редагування товару
        props.setOpenUpdateProduct(false);
        // Popup про успішні зміни (закриття вікна)
        props.toastifySuccess(popupTextSuccess_3);
    };

    // Функція для скидання змінених менеджером значень
    const cancel = () => {
        // Скинути кількість товару
        setCounter(props.product.count);
        // Скинути ціну товару
        setPriceManager(productPrice);
    };

    return (
        <div style={{ position: "relative", padding: "10px" }}>
            <h2 className="mb-3 mx-2 orders-tableHeader">Редагувати замовлення по товару</h2>
            <span className="orders-updateProductItems__btn-close" onClick={changeModal} title="Закрити вікно редагування замовлення по товару">X</span>
            <h6>{props.product.name}</h6>

            <div className="row">
                <div className="col-xxl-8 col-xl-6">
                    <Carousel
                        className="me-3" style={{ height: '200px', width: 'auto' }}
                        showArrows={false} showThumbs={true} showStatus={false} thumbWidth={80}
                    >
                        {
                            // Відобразити всі зображення до продукту
                            (media != '') &&
                            media.map(el => {
                                return (
                                    <div key={el.id}>
                                        <img className="me-3" src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.contentUrl : ''} />
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </div>
                <div className="col-xxl-4 col-xl-6">
                    <div className="orders-update__text">Артикул: {article}</div>
                    <div className="orders-update__text">Штрихкод: {barcode}</div>
                    <div className="orders-update__text">Код1с: {code1c}</div>
                    <div className="orders-update__text">Прихідна ціна: {productIncomePrice} грн.</div>
                    <div className="orders-update__text">
                        <span>Ціна, грн.: </span> <br />
                        <input className='mt-2 me-1 orders-update__input-price' type="number" pattern="[0-9]*" value={priceManager} onChange={priceChange} />
                    </div>
                    <div className="orders-update__text">Сума: {sum} грн.</div>
                    <div className="mt-1 orders-update__text">
                        <span>Кількість, {measurementUnit}: </span> <br />
                        <div className="mt-2 d-flex">
                            <button className='me-1 orders-update__btn-counter' onClick={productDecrement} >-</button>
                            <input className='text-center orders-update__input-counter' type="number" min="0,001" step="any" value={counter} onChange={inputChange} onBlur={notEmpty} />
                            <button className='ms-1 orders-update__btn-counter' onClick={productIncrement}>+</button>
                        </div>
                    </div>
                    <div className="mt-3 d-flex justify-content-center align-items-center">
                        <button className="me-3" onClick={cancel} title="Скинути внесені зміни">Скинути</button>
                        <button onClick={putProductData} style={{ cursor: "pointer" }} title="Зберегти зміни" disabled={disabledBtn}>
                            <span className='me-2'>
                                <CIcon icon={cilSave} className="nav-icon" height={18} />
                            </span>
                            <span>Зберегти</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateProductItem;