import React, { useState } from 'react';
import AxiosInstance from 'src/components/Axios';
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import { useEffect } from 'react';

 


const FilterStorage = ({ setData, clear }) => {
    const [location, setLocation] = useState(false);
    const [locationList, setLocationList] = useState([]);
    useEffect(() => {
        getLocations()
    }, [])
    useEffect(() => {
        if(clear){
            setLocation({});
        }
    }, [clear])
    // Запит за одиницями виміру
    const getLocations = async (inputValue) => {
      let rez = await AxiosInstance.get(`/storages`, { headers: { 'accept': 'application/json' } }).then((response) => {
        // console.log("response", response);
        return response.data.map((result) => ({
          label: result.name,
          value: result.id,
        }));
      });
      setLocationList(rez);
      return rez;
    };
    
    const handleChange = (selectedOption) => {
        setData(selectedOption);
        setLocation(selectedOption);
    };

    return (
        <>
            <Select
                options={locationList}
                value={location}
                onChange={(value) => {
                    handleChange(value);
                }}
                // className='w-25'
                placeholder="Склад"
            />
        </>
    )
}

export default FilterStorage;