import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { useNavigate } from "react-router-dom";
import AxiosInstance from '../Axios';
import { transliterate } from 'transliteration';
import Select from 'react-select'

const keys = [
    { value: 'manufacturer', label: 'Виробник' },
    { value: 'country', label: 'Країна' },
    { value: 'method_of_freezing', label: 'Спосіб замороження' },
    { value: 'expiration_date', label: 'Термін придатності' },
    { value: 'catching_place', label: 'Місце вилову' },
    { value: 'packing_type', label: 'Вид пакування' },
    { value: 'weight', label: 'Вага' },
]

const AddProductInfo = ({ product, actionShowModal, toastifySuccess, toastifyWarning }) => {
    const urlApi = '/product_infos';

    // Текст для popup
    const popupTextSuccess = "Інформація про товар додана";
    const popupTextWarning = "Така інформація про товар вже присутня";

    const [values, setValues] = useState({
        product: `/api/products/${product}`,
        name: "",
        value: "",
        keyName: ""
    });

    const handleChange = (value, name) => {
        if (name == 'keyName') {
            setValues({
                ...values,
                keyName: value.value,
                name: value.label,
            });
        } else {
            setValues({
                ...values,
                [name]: value
            });
        }
    };

    const handleSubmit = event => {
        event.preventDefault();
        let v = true;
        let vError = '';
        // console.log(values);
        if (values.name == '') {
            v = false;
            vError += "-> Назва\n";
        }
        if (values.value == '') {
            v = false;
            vError += "-> Значення\n";
        }

        if (!v) {
            toastifyWarning("Ви не заповнили обовязкові поля:\n" + vError);
        } else {
            AxiosInstance.get(`${urlApi}?product.id=${product}&name=${values.name}`).then((response) => {
                if (response.status === 200) {
                    // console.log("response", response);
                    if (response.data['hydra:totalItems'] === 0) {
                        // Додати інформацію про товар
                        AxiosInstance.post(urlApi, values).then((response) => {
                            if (response.status == 201) {
                                // Popup про успішні зміни
                                toastifySuccess(popupTextSuccess);
                                // return actionShowModal(false);
                                actionShowModal(false);
                            }
                        });
                    } else {
                        // Popup про попередження про неуспішне додавання інформації
                        toastifyWarning(popupTextWarning);
                    }
                };
            });
        };
    };

    return (
        <>
            <CForm onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                            <Select options={keys} onChange={(value) => {
                                handleChange(value, 'keyName');
                            }} />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Значення</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput2" value={values.value} onChange={(e) => handleChange(e.target.value, 'value')} />
                        </div>
                    </div>
                </div>
                <CButton type="submit" color="success">Зберегти</CButton>
            </CForm>
        </>
    )
};

export default AddProductInfo;