import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import AxiosInstance from '../Axios';
import { CBadge, CListGroup, CListGroupItem, CButton, CCol, CRow } from '@coreui/react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';

const CategoriesProducts = (props) => {
    const loadOptions = async (inputValue) => {
        return await AxiosInstance.get('/products?name=' + inputValue, { headers: { 'accept': 'application/json' } }).then((response) => {
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
    };

    const [selectedOption, setSelectedOption] = useState(null);
    let i = 0;
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };
    const [subItem, setSubItem] = useState([]);
    // Текст для popup
    const popupTextAddSuccess = "Товар додано";
    const popupTextDeleteSuccess = "Товар видалено";

    const getParent = async () => {
        AxiosInstance.get('/products?category.id=' + props.category_id, { headers: { 'accept': 'application/json' } }).then((response) => {
            setSubItem(response.data)
        });
    }
    useEffect(() => {
        getParent();
    }, [props]);

    const handleSave = () => {
        let article = {
            product_id: selectedOption.value,
            category_id: props.category_id,
        };

        AxiosInstance.post(`/categories/products`, article).then((response) => {
            console.log(response);
            if (response.status == 201) {
                // Popup про успішні зміни
                props.toastifySuccess(popupTextAddSuccess);
                getParent();
            }
            return response;
        });
    }
    function Deleted(row) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        const response = AxiosInstance.delete(`/categories/${props.category_id}/products/${row.id}`).then((response) => {
                            // Popup про успішні зміни
                            props.toastifySuccess(popupTextDeleteSuccess);
                            getParent();
                        });
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    }
    return (
        <>
            <div className="row mb-2">
                <div className="col"><h3>Товари</h3></div>
                <div className="col d-flex">
                    <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        defaultOptions
                        value={selectedOption}
                        onChange={handleChange}
                        className="flex-grow-1 me-2"
                    />
                    <CButton onClick={handleSave} color="secondary">+</CButton>
                </div>
            </div>

            {
                (subItem.length > 0) ?
                    <CListGroup>
                        {
                            subItem.map((v, k) => {
                                i++;
                                const media = (v.media != undefined && v.media.length > 0) ? process.env.REACT_APP_SERVER_URL + '' + v.media[0].contentUrl : '';
                                return (

                                    <CListGroupItem key={k}>
                                        <div className="d-flex justify-content-between align-items-start">
                                            <div>
                                                {/* <span className='me-2' style={{ width: "295px", backgroundColor: "yellow" }}>{`${i})`}</span> */}
                                                <span className='me-2'>{`${i})`}</span>
                                                {
                                                    (media != "") ?
                                                        <Link to={`/products/update/${v.id}`}>
                                                            <img src={media} style={{ height: '45px', width: '45px' }} />
                                                        </Link>
                                                        :
                                                        // Коли в товара немає зображення, то потрібний порожній простір. Через Row, Col неробив, оскільки великі відступи між колонками. Звичайний width не працює, бо CListGroupItem його ігнорує.
                                                        <span style={{ maxWidth: '45px', color: "transparent" }}>.............</span>
                                                }
                                                <Link to={`/products/update/${v.id}`} className='ms-2'>{v.name}</Link>
                                            </div>
                                            <CButton className='ms-auto' color="danger" variant="outline" onClick={() => { Deleted(v) }}>
                                                Видалити
                                            </CButton>
                                        </div>
                                    </CListGroupItem>
                                )
                            })
                        }
                    </CListGroup >
                    :
                    <p className="ml-3">Товарів не знайдено</p>
            }
        </>
    );
};

export default CategoriesProducts;