import { useState } from "react";

const AddProduct = (props) => {
    console.log(props);
    const [id, setId] = useState(props.id);
    const [products, setProducts] = useState(props.products);

    const List = () => {

        return (
            <ol class="list-group list-group-numbered">{
                products.map((k, v) => {
                    console.log(k);
                    console.log(v);
                    return (

                        <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold">{v.name}</div>
                                {`id:${v.id}, штрих-код:${v.bar}`}
                            </div>
                            {/* <span class="badge bg-primary rounded-pill">14</span> */}
                        </li>

                    );
                })
            }
            </ol>
        )



    }

    return (
        <>
            <hr />
            <h3>Бібліотечні товари</h3>
            <List />
        </>
    );

}


export default AddProduct;