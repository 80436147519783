import React, { useState, useEffect } from "react";
import { cilBasket, cilCheckCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useSelector } from "react-redux";
import { roundTheNumber } from "src/components/store/roundTheNumber";
import { CButton, CCard, CCardBody, CCardHeader, CPopover, CRow, CCol } from '@coreui/react'
import { setBalance } from "src/REDUX/orderSlice";
import { MdBalance } from "react-icons/md";




const MyPopovers = ({product, reduxBalance, measurement_unit}) => {
    return (
        <>
            <p className="h6">Ваш резерв на складі:</p>
            {(reduxBalance != undefined && reduxBalance.length > 0)?
                <ul>
                    { reduxBalance.map((val) => {
                        if(val.product != undefined && val.storage != undefined && val.product.id == product.id)
                            return  <li>{`${val.storage.name} - ${val.count}`} {measurement_unit}</li>;
                    })
                    
                    }
                   
                </ul>
            : <p>У вас нема резервів!</p>}

            <p className="h6">Залишки на складах:</p>
            <ul style={{listStyle: 'none', 'paddingLeft': '10px'}}>
                {product.productBalanceInStorages.map((v) => {
                    if(v.manager === undefined || v.manager === null)
                        return <li style={{fontSize:'12px'}}>{v.storage.name} - <strong>{v.count}</strong> {measurement_unit}</li>;
                })}
                        
            </ul>
        </>
    );
}

// Конкретний продукт у вигляді таблиці
const ProductTableItem = (props) => {
    const reduxPrice = useSelector(state => state.price)

    // if(reduxPrice != undefined){
    //     console.log('redux price:', reduxPrice, '\n props', props)
    // }

    // Розкоментувати для тесту
    // props.product.id = 26327;

    // Кількість товару
    const [counter, setCounter] = useState(1);
    // знижка на товар
    const [isSale, setIsSale] = useState(false);
    const [sale, setSale] = useState(0)
    // Зображення товару
    const cartOrderUrl = (props.product.media != undefined && props.product.media.length > 0) ? `${process.env.REACT_APP_SERVER_URL}${props.product.media[0].contentUrl}` : "";
    // Ціна одиниці товару
    const productPrice = (props.product != undefined && props.product.price != undefined) ? props.product.price : '0';
    // Собівартість за штуку
    const productPriceIncome = (props.product != undefined && props.product.priceIncome != undefined) ? props.product.priceIncome : '0';
    const measurement_unit = (props.product != undefined && props.product.measurement_unit != undefined) ? props.product.measurement_unit.short_name : '';
    // Націнка за товар
    const productMarkUp = (productPriceIncome == 0) ? 0 : (productPrice - productPriceIncome);
    // Чи даний товар є в списку замовлень
    const [added, setAdded] = useState(false);
    // Заблокувати можливість змінення кількості
    const [disabledCount, setDisabledCount] = useState(false);
    // Заблокувати кнопку "Додати до замовлення"
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [balanceStorage, setBalanceStorage] = useState(false);
    const [reserve, setReserve] = useState(false);

    
    // Відслідковувати зміну додавання/видалення з кошика замовлень
    useEffect(() => {
        let isInArray = false;
        if (props.products != false) {
            props.products.some(el => {
                // Порівняти id - чи є в кошику замовлень
                if (el.product.id === props.product.id) {
                    isInArray = true
                };
            });
        };
        setAdded(isInArray);
        setDisabledCount(isInArray);
    }, [props.products]);
    // useEffect(() => {

        
    // }, [props.reduxBalance]);
    useEffect(() => {
        let c = 0;
        props.reduxBalance.forEach(val => {
            // console.log(val);
            if(val.product != undefined && val.storage != undefined && val.product.id == props.product.id)
                c += val.count;
        })
        setReserve(c);
        
    }, [reserve]);

    // Цiни зі знижкою
    useEffect(()=> {
        if(reduxPrice != undefined){
            reduxPrice.map(item => {
                if(item.product.id == props.product.id){
                    setIsSale(true);
                    setSale(item.price)

                }
            })
        }
    })
    //
    const textStyle = {
        color: isSale ? 'green' : 'black', // Зелений колір, якщо isGreen === true, в іншому випадку - чорний
    };
    

    // Збільшити кількість на 1
    function productIncrement() {
        setCounter(counter + 1);
    };
    // Зменшити кількість на 1
    function productDecrement() {
        if (counter > 2) {
            setCounter(counter - 1);
        }
        else {
            setCounter(1);
        }
    };

    // Зміна кількості шт. продуктів вручну
    const inputChange = (event) => {
        // console.log('event.target', event.target);
        // console.log("typeof event.target.value", typeof event.target.value);
        // typeof event.target.value = string, перевести в тип number

        // Перетворити з типу string в тип number
        let value = parseFloat(event.target.value);
        let count = (value <= 0) ? 1 : value;
        setCounter(count);
    };

    // Перевірка counter на NaN
    useEffect(() => {
        // console.log('counter', counter);
        if (isNaN(counter)) {
            setDisabledBtn(true);
        } else {
            setDisabledBtn(false);
        };
    }, [counter]);

    // Функція для кількості (Зміна кількості шт. продуктів вручну)
    const notEmpty = (event) => {
        if (event.target.value === "") {
            setCounter(1);
        } else {
            return;
        };
    };

    // Додавання товару до замовлення
    const handleAddToOrder = (product) => {
        const orderCount = { ...product, count: counter };
        // const orderCount = { ...product, count: counter, productPrice: sale };
        props.addToOrder(orderCount);
    };
    

    return (
        <tr>
            <td className="orders-choose__td-img">
                <img src={cartOrderUrl} className="orders-choose__img" />
            </td>
            <td className="orders-choose__name w-50" onClick={() => props.setSelectedProductId(props.product.id)} title="Показати інформацію про товар">
                <div>
                    <div style={{ textDecoration: "underLine", color: "rgb(3, 152, 158)" }}>{props.product.name}</div>
                    <div>Артикул: {props.product.article}</div>
                    <div className="orders-choose__text">Штрихкод: {props.product.barcode}</div> 
                    <p className='text-muted'><strong>Склад: </strong>{(props.product.storageElement != undefined) ? props.product.storageElement.storage.name : '...'}</p>
                </div>
            </td>
            {/* <td className="orders-choose__text orders-choose__PC">{props.product.code1c}</td> */}
            {/* <td className="orders-choose__text orders-choose__PC">{props.product.barcode}</td> */}
            <td className="orders-choose__count">
                    <CPopover
                        title={(props.product.balance > 0) ? 'Баланс на складі - '+props.product.balance.toFixed(3) + ' '  + measurement_unit: 'Нема на складі' }
                        content={<MyPopovers product={props.product} reduxBalance={props.reduxBalance} measurement_unit={measurement_unit}/>}
                        placement="top"
                    >
                        <p>
                        <span style={{fontSize:'14px', textDecoration: 'underline'}} ><MdBalance/>{(props.product.balance > 0) ? props.product.balance.toFixed(3) : 0 }/<strong>{reserve} {measurement_unit}</strong></span>
                        </p>
                    </CPopover>
                <div className="orders-choose__count-1r">
                    <input className='orders-choose__count-input me-1 text-center' type="number" min="0,001" step="any" value={counter} onChange={inputChange} onBlur={notEmpty} disabled={disabledCount} />
                </div>
                <div className="orders-choose__count-2r">
                    <button className='orders-choose__count-button me-1 text-center' onClick={productDecrement} disabled={disabledCount}>-</button>
                    <button className='orders-choose__count-button' onClick={productIncrement} disabled={disabledCount}>+</button>
                </div>
            </td>
            <td className="orders-choose__text">
                <div className="orders-choose__TC">
                    <span style={textStyle}>{isSale ? sale : productPrice}</span>
                    <span>{roundTheNumber(productMarkUp)}</span>
                    <span>{roundTheNumber(productPriceIncome)}</span>
                </div></td>
            <td className="orders-choose__cart">
                {
                    (!added) ?
                        <button title="Додати товар до замовлення"
                            onClick={() => handleAddToOrder(props.product)} style={{ cursor: "pointer" }} disabled={disabledBtn}
                        >
                            <CIcon icon={cilBasket} customClassName="nav-icon" height={15} />
                        </button>
                        :
                        <CIcon icon={cilCheckCircle} customClassName="nav-icon" height={18} style={{ color: "green" }} />
                }
            </td>
        </tr>
    )

};

export default ProductTableItem;