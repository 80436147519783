import React, { useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { useNavigate } from "react-router-dom";
import AxiosInstance from '../Axios';
import { transliterate } from 'transliteration';

const AttributesCreateForm = (props) => {
    // console.log("AttributesCreateForm ", props);
    let data = props.data;

    const [name, setName] = useState((data != undefined) ? data.name : '');
    const [slug, setSlug] = useState((data != undefined) ? data.slug : '');
    // Текст для popup
    const popupTextPostSuccess = "Атрибут створено";
    const popupTextPutSuccess = "Атрибут оновлено";

    let navigate = useNavigate();

    const handleSubmit = event => {
        event.preventDefault();
        let slugT;
        if (slug == '')
            slugT = transliterate(name).replace(' ', '');
        else
            slugT = transliterate(slug).replace(' ', '');

        let article = {
            name: name,
            slug: slugT,
        };

        // console.log("AttributesCreateForm article", article);

        // При перегляді атрибуту, можливо редагувати і передати оновлений article
        if (data != undefined) {
            AxiosInstance.put('/attributes/' + data.id, article).then((response) => {
                // console.log("/attributes update response", response)
                // Popup про успішні зміни
                props.toastifySuccess(popupTextPutSuccess);
                navigate("/attributes/update/" + response.data.id);
            })
        } else {
            // При створенні атрибуту
            AxiosInstance.post('/attributes', article).then((response) => {
                // console.log("/attributes response", response)
                if (response.status == 201) {
                    // Popup про успішні зміни
                    props.toastifySuccess(popupTextPostSuccess);
                    // alert('Новий атрибут створено', article)
                    return navigate("/attributes/update/" + response.data.id);
                }
                return response;
            });
        }
    };

    return (
        <>
            <CForm onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" required />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Синонім</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput2" value={slug} onChange={(e) => setSlug(e.target.value)} autoComplete="off" />
                        </div>
                    </div>
                </div>
                <CButton type="submit" color="success">Зберегти</CButton>
            </CForm>
        </>
    );
};

export default AttributesCreateForm;