import React, { useRef, useState, useEffect } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import SubCategories from 'src/components/category/SubCategories';
import CategoriesProducts from 'src/components/category/CategoriesProducts';
import CategoriesForm from 'src/components/category/Form';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';

const urlApi = '/categories';
const urlRouter = '/category';

export async function LoaderCategoryUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const CategoryForm = () => {
  const { data } = useLoaderData();
  const [MyData, setMyData] = useState(data);

  // Викликає popup з власного компонента
  const popup = MyToastify();

  useEffect(() => {
    setMyData(data);
  }, [data]);

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CategoriesForm data={MyData} type='page' toastifySuccess={popup.toastifySuccess} />
      <hr />
      {(MyData != undefined) ? <SubCategories categories={MyData.categories} parent={MyData.id} parent_name={MyData.name} toastifySuccess={popup.toastifySuccess} /> : ''}
      <hr />
      {(MyData != undefined) ? <CategoriesProducts category_id={MyData.id} toastifySuccess={popup.toastifySuccess} /> : ''}


    </>
  );
};

export default CategoryForm;
