import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';

// Функція для створення замовлення на основі preOrder
const CreateOrderFromPreOrder = () => {

    const objOrderData = {
        account: '',
        client: '',
        //     agreement: '',
        // status: 'aproved',
        description: '',
        dateOrder: null,
        curency: 'uah',
        paymentMethod: 'cash',
        sumOrdered: 0,
        curencyRate: 1,
        discountTotal: 0,
        discountPercent: 0,
        // shippingAddressStreet: '',
        shippingAddressCity: '',
        // shippingAddressBuilding: '',
        // shippingAddressCountry: 'Україна',
        // shippingAddressDate: '',
        synced: false,
        manager: '',
        preOrder: '',
    };
    const urlApi = '/orders';
    const navigate = useNavigate();
    // Замовлення, яке буде створюватися з preOrders
    const [order, setOrder] = useState(false);
    // Клоновані дані
    const [values, setValues] = useState(objOrderData);

    // Створити замовлення
    useEffect(() => {
        // console.log('values', values);
        if (order !== false) {
            // console.log("Зробити реалізацію");
            AxiosInstance.post(urlApi, values).then((response) => {
                if (response.status == 201) {
                    // alert("Замовлення створено");
                    // console.log("response post", response);
                    // Перейти на сторінку створеного замовлення
                    navigate(`/orders/update/${response.data.id}`);
                }
                else {
                    alert("Error");
                }
            });
        }
    }, [values]);

    // Наповнити дані нового замовлення
    useEffect(() => {
        if (order !== false) {
            // console.log('order', order);

            // Масив з доданими до замовлення товарами 
            const order_Products = order.preOrderProducts.map((product) => {
                // id для pre_order_products
                let elId = product.id;
                delete product['@id'];
                delete product.id;
                delete product['@type'];
                delete product.acceptedProducts;
                delete product.preOrder;
                // delete product.loadInvoiceProducts;
                return (
                    {
                        ...product,
                        measurementUnit: (product.measurementUnit != undefined) ? `/api/measurment_units/${product.measurementUnit.id}` : `/api/measurment_units/${product.product.measurement_unit.id}`,
                        product: `/api/products/${product.product.id}`,
                        preOrderProduct: `/api/pre_order_products/${elId}`
                    }
                )
            });

            setValues({
                ...values,
                account: `/api/accounts/${order.account.id}`,
                client: `/api/users/${order.client.id}`,
                // agreement: (order.agreement != undefined) ? `/api/agreements/${order.agreement.id}` : '',
                status: "created",
                description: `Реалізовано з попереднього замовлення #${order.id} ${order.description}`,
                // dateOrder: order.dateOrder,
                curency: order.preOrderProducts[0].curency,
                paymentMethod: order.paymentMethod,
                sumOrdered: order.sumOrdered,
                // curencyRate: order.curencyRate,
                // discountTotal: order.discountTotal,
                // discountPercent: order.discountPercent,
                // shippingAddressStreet: order.shippingAddressStreet,
                shippingAddressCity: order.shippingAddressCity,
                // shippingAddressBuilding: order.shippingAddressBuilding,
                // shippingAddressCountry: order.shippingAddressCountry,
                // shippingAddressDate: order.shippingAddressDate,
                synced: false,
                manager: `/api/users/${order.account.manager.id}`,
                orderProducts: order_Products,
                preOrder: `/api/pre_orders/${order.id}`
            });
        }
    }, [order]);

    return {
        setOrder,
    };
};

export default CreateOrderFromPreOrder;