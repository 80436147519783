import { useState, useEffect } from "react";
import AxiosInstance from 'src/components/Axios';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { cilBasket, cilCheckCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

// Інформація про товар
const ProductInfo = (props) => {
    // console.log("ProductInfo props", props);

    const urlApi = '/products';
    // Дані про продукт
    const [product, setProduct] = useState(false);
    // Зображення для продукту
    const media = (product.media != undefined && product.media.length > 0) ? product.media : "";
    // Вхідна ціна одиниці товару
    const productIncomePrice = (props.product != undefined && props.product.priceIncome != undefined) ? props.product.priceIncome : '0';
    // Чи даний товар є в списку замовлень
    const [added, setAdded] = useState(false);
    // Кількість товару
    const [counter, setCounter] = useState(1);
    const [count, setCount] = useState(1);
    // Заблокувати кнопку "Додати до замовлення"
    const [disabledBtn, setDisabledBtn] = useState(false);
    // Текст для popup
    const popupTextSuccess_1 = "Відкрито вікно з інформацією про товар";
    const popupTextSuccess_2 = "Вікно з інформацією про товар закрито";

    // Вивести повідомлення коли відкривається вікно з інформацією про товар
    useEffect(() => {
        // Popup про успішні зміни
        props.toastifySuccess(popupTextSuccess_1);
    }, [props.selectedProductId]);

    // Збільшити кількість на 1
    function productIncrement() {
        setCounter(counter + 1);
    }
    // Зменшити кількість на 1
    function productDecrement() {
        if (counter > 2) {
            setCounter(counter - 1);
        }
        else {
            setCounter(1);
        }
    };
    useState(() => {
        setCount(counter);
    }, [counter]);

    // Зміна кількості шт. продуктів вручну
    const inputChange = (event) => {
        // console.log("inputChange event", event.target.value);
        // typeof event.target.value = string, перевести в тип number

        // Перетворити з типу string в тип number
        let value = parseFloat(event.target.value);
        let count = (value <= 0) ? 1 : value;
        setCounter(count);
    };

    // Перевірка counter на NaN
    useEffect(() => {
        // console.log('counter', counter);
        if (isNaN(counter)) {
            setDisabledBtn(true);
        } else {
            setDisabledBtn(false);
        };
    }, [counter]);

    // Функція для кількості (Зміна кількості шт. продуктів вручну)
    const notEmpty = (event) => {
        if (event.target.value === "") {
            setCounter(1);
        } else {
            return;
        };
    };

    // Виклик ф-ції для запиту за інформацією про продукт
    useEffect(() => {
        if (props.selectedProductId != false) {
            // console.log("props.selectedProductId", props.selectedProductId);
            getProduct();
        }
    }, [props.selectedProductId]);

    // Функція для запиту за інформацією про продукт
    const getProduct = () => {
        AxiosInstance.get(`${urlApi}/${props.selectedProductId}`).then((response) => {
            // console.log("get /api/products/ response", response);
            setProduct(response.data);
        });
    };

    // Додавання товару до замовленя
    const handleAddToOrder = (product) => {
        // Додати до продукту кількість штук count: counter
        const orderCount = { ...product, count: counter }
        // Додавати товар в кошик замовлень
        props.addToOrder(orderCount);
    };

    // Функція для відкриття модального вікна з списком доданих товарів
    const changeModal = () => {
        // Показати таблицю з доданими до замовлення товарами
        props.setShowTable(true);
        // Для запиту за списком з доданими товарами до замовлення
        props.setGetOP(true);
        // Popup про успішні зміни (закриття вікна)
        props.toastifySuccess(popupTextSuccess_2);
    };

    // Відслідковувати зміну додавання/видалення з кошика замовлень
    useEffect(() => {
        let isInArray = false;
        props.products.some(el => {
            // Порівняти id - чи є в кошику замовлень
            if (product != false && el.product.id === product.id) {
                isInArray = true
            };
        });
        setAdded(isInArray);
    }, [product]);

    return (
        <div style={{ position: "relative", padding: "10px" }}>
            <h2 className="mb-3 mx-2 orders-tableHeader">Інформація про товар</h2>
            <span className="orders-productInfo__btn-close" onClick={changeModal} title="Закрити вікно інформації про товар">X</span>
            <h6>{product.name}</h6>

            <div className="row">
                <div className="col-xxl-8 col-xl-6">
                    <Carousel
                        className="me-3" style={{ height: '200px', width: 'auto' }}
                        showArrows={false} showThumbs={true} showStatus={false} thumbWidth={60}
                    >
                        {
                            // Відобразити всі зображення до продукту
                            (media != '') &&
                            media.map(el => {
                                return (
                                    <div key={el.id}>
                                        <img className="me-3" src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.contentUrl : ''} />
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </div>
                <div className="col-xxl-4 col-xl-6">
                    <div className="orders-productInfo__text">Артикул: {product.article}</div>
                    <div className="orders-productInfo__text">Штрихкод: {product.barcode}</div>
                    <div className="orders-productInfo__text">Вхідна ціна: {productIncomePrice} грн.</div>
                    <div className="orders-productInfo__text">Ціна: {product.price} грн.</div>
                    <div className="orders-productInfo__text">Код1с: {product.code1c}</div>
                    <div className="mt-2">
                        {
                            (!added) ?
                                // Якщо товар не доданий до замовлення
                                <div className="orders-productInfo__text">
                                    <div className="orders-productInfo__count">
                                        <button className='me-3' onClick={productDecrement} style={{ width: "45px" }}>-</button>
                                        <input className='me-3 text-center orders-productInfo__count-input' type="number" min="0,001" step="any" value={counter} onChange={inputChange} onBlur={notEmpty} />
                                        <button className='orders-productInfo__count-btn' onClick={productIncrement}>+</button>
                                    </div>

                                    <div className='mt-2 orders-productInfo__btn-container'>
                                        <button onClick={() => handleAddToOrder(product)} style={{ cursor: "pointer" }} disabled={disabledBtn}>
                                            <span className='me-2'>
                                                <CIcon icon={cilBasket} customClassName="nav-icon" height={18} />
                                            </span>
                                            <span>Додати</span>
                                        </button>
                                    </div>
                                </div>
                                :
                                // Якщо товар вже доданий до замовлення
                                <div className="orders-productInfo__text">
                                    <CIcon icon={cilCheckCircle} customClassName="nav-icon" height={18} style={{ color: "green" }} />
                                    <span className="ms-2">Даний товар вже доданий</span>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProductInfo;