import React, { useState, useEffect } from 'react';
import BasicModal from "../Modal";
import CategoriesForm from "./Form";
import AxiosInstance from '../Axios';
import { CBadge, CListGroup, CListGroupItem, CButton, CIcon } from '@coreui/react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';


const SubCategories = (props) => {
    const [showModal, setShowModal] = useState(true);
    // Текст для popup
    const popupTextSuccess = "Підкатегорію видалено";

    const actionShowModal = (rez) => {
        setShowModal(rez);
        if (!rez)
            getParent();
    }
    const [subItem, setSubItem] = useState([]);
    const getParent = async () => {
        let res = await AxiosInstance.get('/categories?page=1&parent=' + props.parent, { headers: { 'accept': 'application/json' } }).then((response) => {
            setSubItem(response.data);
        });
    }

    useEffect(() => {
        getParent();
    }, [props]);

    function Deleted(row) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        const response = AxiosInstance.delete('/categories/' + row.id).then((response) => {
                            // Popup про успішні зміни
                            props.toastifySuccess(popupTextSuccess);
                            getParent();
                        });
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    }

    return (
        <>
            <div className='row'>
                <div className='col'> <h3>Під категорії</h3></div>
                <div className='col d-flex justify-content-end'>
                    <BasicModal
                        show={showModal}
                        actionShowModal={actionShowModal}
                        title={`Створіть Підкатегорію до #${props.parent} ${props.parent_name}`}
                        content={
                            <CategoriesForm
                                data={undefined}
                                type='sub'
                                actionShowModal={actionShowModal}
                                parent={props.parent}
                                toastifySuccess={props.toastifySuccess}
                            />
                        }
                    />
                </div>
            </div>

            {
                (subItem.length > 0) ?
                    <CListGroup>

                        {
                            subItem.map((v, k) => {
                                return (

                                    <CListGroupItem key={k} className="d-flex justify-content-between align-items-center">
                                        <Link to={`/category/update/${v.id}`} >{v.name}</Link>

                                        <CButton color="danger" variant="outline" onClick={() => { Deleted(v) }}>
                                            Видалити
                                        </CButton>

                                    </CListGroupItem>

                                )
                            })
                        }

                    </CListGroup>
                    :
                    <p className="ml-3">Категорій не знайдено</p>
            }



        </>
    );
}

export default SubCategories;