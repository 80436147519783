import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, } from '@coreui/react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import AddProductInfo from 'src/components/product/AddProductInfo';
import ViewProductInfo from 'src/components/product/ViewProductInfo';
import BasicModal from 'src/components/Modal';
import ProductAttributes from 'src/components/product/ProductAttributes';
import MediaFrom from 'src/components/media/MediaFrom';
import DeleteImgFrom from 'src/components/media/DeleteImgFrom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { DataObject } from '@mui/icons-material';
import ProductCategories from 'src/components/product/ProductCategories';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import Select from 'react-select';
import WarningWhenEditing from 'src/components/product/WarningWhenEditing';


const urlApi = '/products';
const urlRouter = '/products';

export async function LoaderProductsUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      // console.log("get /api/products/ response", response);
      return response;
    });
    return res;
  }
  return false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const ProductForm = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData();

  const [MyData, setMyData] = useState(data);

  useEffect(() => {
    // console.log('data', data);
    setMyData(data);
  }, [data]);

  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [description, setDescription] = useState((data != undefined) ? data.description : '');
  const [code1c, setCode1c] = useState((data != undefined) ? data.code1c : '');
  const [barcode, setBarcode] = useState((data != undefined) ? data.barcode : '');
  const [status, setStatus] = useState((data != undefined) ? data.status : '');
  const [price, setPrice] = useState((data != undefined) ? data.price : '');
  const [discounts, setDiscounts] = useState((data != undefined) ? data.discounts : '');

  const [top, setTop] = useState((data != undefined) ? data.top : '0');
  const [show, setShow] = useState((data != undefined && data.show != null) ? data.show : false);
  // Для checkbox при ініціалазації
  const initShow = (show != 1) ? false : true;
  const initTop = (top != 1) ? false : true;
  const [changeTop, setChangeTop] = useState(initTop);

  const [article, setArticle] = useState((data != undefined) ? data.article : '');
  const [nds, setNds] = useState((data != undefined) ? data.nds : '');
  const [packaging, setPackaging] = useState((data != undefined) ? data.packaging : '');
  const [media, setMedia] = useState((data.media != undefined && data.media.length > 0) ? data.media : '');

  const [productToOrder, setProductToOrder] = useState((data != undefined) ? data.productToOrder : '0');
  // Для checkbox при ініціалазації
  const initProductToOrder = (productToOrder != 1) ? false : true;
  const [changeProductToOrder, setChangeProductToOrder] = useState(initProductToOrder);

  const [showModal, setShowModal] = useState(true);
  const [update, setUpdate] = useState(false);
  // Для модального вікна "Додати зображення"
  const [showModalMedia, setShowModalMedia] = useState(true);
  // Для модального вікна "Видалення зображення"
  const [showModalMediaDelete, setShowModalMediaDelete] = useState(true);

  const [storageElement, setStorageElement] = useState((data != undefined && data.storageElement != null) ? { label: data.storageElement.name, value: data.storageElement.id } : {});
  const [defaultStorageElement, setDefaultStorageElement] = useState([]);

  const [measurmentUnit, setMeasurmentUnit] = useState((data != undefined && data.measurement_unit != null) ? { label: data.measurement_unit.name, value: data.measurement_unit.id } : {});
  const [defaultMeasurmentUnit, setDefaultMeasurmentUnit] = useState([]);

  const editorRef = useRef(null);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess = "Товар збережено";

  // Відкрити/закрити вікно з попередженням про редагування товару
  const [openWarning, setOpenWarning] = useState(true);

  // Для зміни значення checkbox для поля "Хіти продаж"
  useEffect(() => {
    changeTop ? setTop(1) : setTop(0)
  }, [changeTop]);

  useEffect(() => {
    storageElementList();
    measurmentUnitList();
  }, []);

  // useEffect(() => {
  //   console.log('show', show);
  // }, [show]);

  // Для зміни значення checkbox для поля "Товар під замовлення"
  useEffect(() => {
    changeProductToOrder ? setProductToOrder(1) : setProductToOrder(0)
  }, [changeProductToOrder]);

  // Обновити після додавання/видалення зображення продукту
  const UpdateMedia = () => {
    let res = AxiosInstance.get(`${urlApi}/${data.id}`).then((response) => {
      // console.log("UpdateMedia response", response);
      if (response.status === 200) {
        setMedia(response.data.media);
        // setUpdate(true);
      }
    });
  };

  const storageElementList = async (inputValue) => {
    let rez = await AxiosInstance.get(`/storage_elements`, { headers: { 'accept': 'application/json' } }).then((response) => {
      // console.log("response", response);
      return response.data.map((result) => ({
        label: result.name,
        value: result.id,
        contacts: result.contacts
      }));
    });
    setDefaultStorageElement(rez);
    return rez;
  };

  // Запит за одиницями виміру
  const measurmentUnitList = async (inputValue) => {
    let rez = await AxiosInstance.get(`/measurment_units`, { headers: { 'accept': 'application/json' } }).then((response) => {
      // console.log("response", response);
      return response.data.map((result) => ({
        label: result.name,
        value: result.id,
      }));
    });
    setDefaultMeasurmentUnit(rez);
    return rez;
  };


  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(show);
      const data_response = {
        name: name,
        description: description,
        code1c: code1c,
        barcode: barcode,
        status: status,
        price: parseFloat(price),
        discounts: parseFloat(discounts),
        top: parseFloat(top),
        show: show,
        article: article,
        nds: parseFloat(nds),
        packaging: parseFloat(packaging),
        productToOrder: parseFloat(productToOrder),
        active: 'on',
        measurement_unit: `/api/measurment_units/${measurmentUnit.value}`
      };
      if (storageElement.value != undefined)
        data_response['storageElement'] = `/api/storage_elements/${storageElement.value}`;
      if (data != undefined) {
        const response = AxiosInstance.put(`${urlApi}/${data.id}`, data_response).then((response) => {
          if (response.status === 200) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextSuccess);
          }
          return navigate(`${urlRouter}/update/${data.id}`);
        });
      } else {
        const response = AxiosInstance.post(`${urlApi}`, data_response).then((response) => {
          if (response.status === 201) {
            return navigate(urlRouter);
          }
        });
      }
    }
  };

  const actionShowModal = (rez) => {
    setShowModal(rez);
    if (!rez)
      setUpdate(true);
  };

  // Для модального вікна "Додати зображення"
  const actionShowModalMedia = (rez) => {
    setShowModalMedia(rez);
    // console.log(11111);
    if (!rez)
      UpdateMedia();
  };

  // Для модального вікна "Видалення зображення"
  const actionShowModalMediaDelete = (rez) => {
    setShowModalMediaDelete(rez);
    // console.log(11111);
    if (!rez)
      UpdateMedia();
  };

  const handleChange = (value) => {
    setStorageElement(value);
  };

  // Для зміни значеь "Одиниці виміру"
  const handleChangeMeasurmentUnit = (value) => {
    setMeasurmentUnit(value);
  };

  // Функція для закриття модального вікна з попередженням про редагування товару
  const handleCloseWarning = () => {
    // Закрити модальне з попередженням
    setOpenWarning(false);
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      {/* Модальне вікно з попередженням про редагування товару */}
      <WarningWhenEditing openWarning={openWarning} handleCloseWarning={handleCloseWarning} />

      <CForm onSubmit={handleSubmit}>
        <h1 className="mb-3">{name}</h1>
        <div className="row mb-3">
          {/* <div className="col-6 d-flex"> */}
          <div className="col-12 d-flex">
            {/* <img className="me-3" src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + media[0].contentUrl : ''} style={{ height: '300px', width: 'auto' }} /> */}
            <Carousel
              className="me-3"
              style={{ height: '300px', width: 'auto' }}
              showArrows={false}
              showThumbs={true}
              width={400}
              showStatus={false}
              thumbWidth={80}
            >
              {
                // Відобразити всі зображення до продукту
                (media != '') &&
                media.map(el => {
                  return (
                    <div key={el.id}>
                      <img className="me-3" src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.contentUrl : ''} />
                    </div>
                  )
                })
              }
            </Carousel>

            <div>
              <div className="mb-3">
                <BasicModal
                  show={showModalMedia}
                  actionShowModal={actionShowModalMedia}
                  title={`Додати зображення товара`}
                  btn_name='Додати зображення'
                  content={
                    <MediaFrom product_id={data.id}
                      actionShowModal={actionShowModalMedia}
                      LoaderProductsUpdate={LoaderProductsUpdate} UpdateMedia={UpdateMedia}
                      toastifySuccess={popup.toastifySuccess}
                    />
                  }
                />
              </div>
              <div className="mb-3">
                <BasicModal
                  show={showModalMediaDelete}
                  actionShowModal={actionShowModalMediaDelete}
                  title={`Видалити зображення товара`}
                  btn_name='Видалити зображення'
                  content={
                    <DeleteImgFrom product_id={data.id}
                      actionShowModal={actionShowModalMediaDelete}
                      media={media} UpdateMedia={UpdateMedia} setShowModalMedia={actionShowModalMediaDelete}
                      toastifySuccess={popup.toastifySuccess}
                    />
                  }
                />
              </div>
            </div>
          </div>
          {/* <div className="col-6">
            <div className="row mb-3">
              <div className="col-2"><CFormLabel htmlFor="exampleFormControlInput1">Дата введеня:</CFormLabel></div>
              <div className="col-10">
                Автоматично виведеться текст
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-2"><CFormLabel htmlFor="exampleFormControlInput1">Дата змінення:</CFormLabel></div>
              <div className="col-10">
                Автоматично виведеться текст
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-2"><CFormLabel htmlFor="exampleFormControlInput1">Хто змінив:</CFormLabel></div>
              <div className="col-10">
                Автоматично виведеться текст
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-2"><CFormLabel htmlFor="exampleFormControlInput1">Ким створено:</CFormLabel></div>
              <div className="col-10">
                Автоматично виведеться текст
              </div>
            </div>
          </div> */}
        </div>
        <div className="mb-3">
          <CFormCheck id="exampleFormControlInput8" label="Товар продається" checked={show} onChange={() => setShow(!show)} />
        </div>
        <div className="mb-3">
          <CFormCheck id="exampleFormControlInput8" label="Хіт продаж" checked={initTop} onChange={() => setChangeTop(!changeTop)} />
        </div>
        <div className="mb-3">
          <CFormCheck id="exampleFormControlInput8" label="Товар під замовлення" checked={initProductToOrder} onChange={() => setChangeProductToOrder(!changeProductToOrder)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Елемент Складу</CFormLabel>
          <Select
            options={defaultStorageElement}
            value={storageElement}
            onChange={(value) => { handleChange(value) }}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Опис</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={description} onChange={(e) => setDescription(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">code1c</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" value={code1c} onChange={(e) => setCode1c(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Штрихкод</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput4" value={barcode == null ? "" : barcode} onChange={(e) => setBarcode(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Статус</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput5" value={status == null ? "" : status} onChange={(e) => setStatus(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Одиниці виміру</CFormLabel>
          <Select
            options={defaultMeasurmentUnit}
            value={measurmentUnit}
            onChange={(value) => { handleChangeMeasurmentUnit(value) }}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Ціна</CFormLabel>
          <CFormInput type="number" id="exampleFormControlInput6" value={price == null ? "" : price} onChange={(e) => setPrice(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Знижки</CFormLabel>
          <CFormInput type="number" id="exampleFormControlInput7" value={discounts == null ? "" : discounts} onChange={(e) => setDiscounts(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Артикул</CFormLabel>
          <CFormInput type="number" id="exampleFormControlInput9" value={article} onChange={(e) => setArticle(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">nds</CFormLabel>
          <CFormInput type="number" id="exampleFormControlInput10" value={nds} onChange={(e) => setNds(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">packaging</CFormLabel>
          <CFormInput type="number" id="exampleFormControlInput11" value={packaging} onChange={(e) => setPackaging(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div>

        <CButton type="submit" color="success" >Зберегти товар</CButton>
        <Link to="/products" className="ms-3"><CButton type="submit" color="secondary">Відміна</CButton></Link>
      </CForm>

      {
        (data != undefined) ?
          <>
            <hr />
            <div className='row'>
              <div className='col'> <h3>Інформація про товар</h3></div>
              <div className='col d-flex justify-content-end'>
                <BasicModal
                  show={showModal}
                  actionShowModal={actionShowModal}
                  title={`Додати інформацію про товар`}
                  content={
                    <AddProductInfo
                      actionShowModal={actionShowModal}
                      product={data.id}
                      toastifySuccess={popup.toastifySuccess}
                      toastifyWarning={popup.toastifyWarning}
                    />
                  }
                />
              </div>
            </div>

            <ViewProductInfo product={data.id} update={update} setUpdate={setUpdate} toastifySuccess={popup.toastifySuccess} />
          </>
          : ''
      }

      <hr />
      {/* Атрибути */}
      <ProductAttributes productId={data.id} attributeItems={data.attributeItems} toastifySuccess={popup.toastifySuccess} />
      <hr />
      {/* Категорії */}
      {(MyData != undefined) ? <ProductCategories product_id={MyData.id} toastifySuccess={popup.toastifySuccess} /> : ''}

    </>
  );
};

export default ProductForm;