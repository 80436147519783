import React, { useEffect, useState } from 'react';
import { Modal, StyledEngineProvider, Typography, Box } from '@mui/material';
import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react';
import AxiosInstance from 'src/components/Axios';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';

// Calculation для договору
const AgreementCalculationModal = (props) => {
    // console.log('props', props);

    // Стилі для модального вікна
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
    };

    const urlApi = '/calculations';
    const page = 1;
    const itemsPerPage = 30;

    const [rows, setRows] = useState([]);
    const [rowCountState, setRowCountState] = useState(0);
    const [agreements, setAgreements] = useState("");
    const agreementsName = (agreements != "" && agreements.row.name != undefined) ? agreements.row.name : "";
    const agreementsNumber = (agreements != "" && agreements.id != undefined) ? agreements.id : "";

    // Функція для запиту за розрахунками по договору
    const getRowsTable = (page) => {
        AxiosInstance.get(`${urlApi}?page=${page}&itemsPerPage=${itemsPerPage}&agreement.id=${props.dataAgreements.id}`).then((response) => {
            setRows(response.data['hydra:member']);
            setRowCountState(response.data['hydra:totalItems']);
        });
    };


    useEffect(() => {
        if (props.dataAgreements != undefined) {
            // console.log('props.dataAgreements', props.dataAgreements);
            setAgreements(props.dataAgreements);
            getRowsTable(page);
        }
    }, [props.dataAgreements]);


    const handlePageChange = (page) => {
        getRowsTable(page + 1);
    }

    const columns = [
        { field: 'coming', headerName: 'Прихід', width: 80 },
        { field: 'discharge', headerName: 'Розхід', width: 80 },
        { field: 'finalBalance', headerName: 'Залишок', width: 80 },
        { field: 'movementDocument', headerName: 'Реєстратор', width: 350 },
        {
            field: 'date', headerName: 'Період', width: 100,
            renderCell: (params) => {
                return `${dayjs(params.row.date).format('DD.MM.YYYY')}`
            }
        },
        // { field: 'agreement', headerName: 'Договір', width: 70 },
        { field: 'agreementType', headerName: 'Вид Договору', width: 120 },
        { field: 'typeMutualSettlements', headerName: 'Вид взаєморозрахунків', width: 180 },
        { field: 'oragnazationName', headerName: 'Організація', width: 150 },
    ]

    return (
        <StyledEngineProvider injectFirst>
            <Modal
                open={props.openCalculation}
                onClose={props.handleCloseCalculation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: "center" }}>
                        <CCard className="mb-2">
                            <CCardHeader>
                                <strong>Договір №{agreementsNumber} {agreementsName}</strong>
                            </CCardHeader>
                            <CCardBody>
                                <div style={{ height: 400, width: '100%' }}>
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        pageSize={itemsPerPage}
                                        rowsPerPageOptions={[itemsPerPage]}
                                        pagination
                                        rowCount={rowCountState}
                                        paginationMode="server"
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </CCardBody>
                        </CCard>

                        <CButton color="secondary" onClick={() => props.handleCloseCalculation()}>Закрити</CButton>
                    </Typography>
                </Box>
            </Modal>
        </StyledEngineProvider >
    );
};

export default AgreementCalculationModal;