import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { useLoaderData } from 'react-router-dom';
import axios from "axios";

const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

export async function LoaderNewsUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/news/' + params.newsId, header).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
};

const newsForm = () => {
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [newsShot, setNewsShot] = useState((data != undefined) ? data.newsShot : '');
  const editorRef = useRef(null);

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      const article = {
        name: name,
        newsShot: newsShot,
        newsFull: editorRef.current.getContent(),
      };
      if (data != undefined) {
        const response = axios.put(process.env.REACT_APP_SERVER_URL + '/api/news/' + data.id, article, header);
      } else {
        const response = axios.post(process.env.REACT_APP_SERVER_URL + '/api/news', article, header);
      }
    }
  };

  return (
    <>
      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Короткий опис</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={newsShot} onChange={(e) => setNewsShot(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.newsFull : ''}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div>
        <CButton type="submit" color="success">Submit</CButton>
      </CForm>
    </>
  );
};

export default newsForm;