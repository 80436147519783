import React, { useRef, useState, useEffect } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import FormOrders from 'src/components/pre_orders/FormOrders';
import ViewOrders from 'src/components/pre_orders/ViewOrders';
import FormProductOrders from 'src/components/pre_orders/FormProductOrders';
import ViewProductOrders from 'src/components/pre_orders/ViewProductOrders';
import BasicModal from 'src/components/Modal';

const urlApi = '/pre_orders';
const urlRouter = '/pre-orders';

export async function LoaderPreOrdersUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const PreOrdersForm = () => {
  const { data } = useLoaderData();

  const [MyData, setMyData] = useState(data);
  const [show, setShow] = useState((data == undefined) ? false : true);

  useEffect(() => {
    setMyData(data);
  }, [data]);

  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [synonym, setSynonym] = useState((data != undefined) ? data.synonym : '');
  const [mainImg, setmainImg] = useState((data != undefined) ? data.images : '');

  const [showModal, setShowModal] = useState(true);

  const actionShowModal = (rez) => {
    setShowModal(rez);
    if (!rez)
      getParent();
  }

  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Дані замовлення</strong> <small>{(data != undefined) ? `#${MyData.id}` : ''}</small>
        </CCardHeader>
        <CCardBody>
          {(show) ? <ViewOrders data={MyData} /> : <FormOrders data={MyData} />}

        </CCardBody>
      </CCard>
      {(data != undefined) ?
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Товари</strong>
            <BasicModal
              show={showModal}
              actionShowModal={actionShowModal}
              title={`Додати товар до замовлення №${MyData.id}`}
              content={
                <FormProductOrders data={MyData} actionShowModal={actionShowModal} />
              }
            />
          </CCardHeader>
          <CCardBody>
            {(show) ? <ViewProductOrders data={MyData} /> : <FormProductOrders data={MyData} />}
          </CCardBody>
        </CCard>
        : ''
      }



      {/* <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Синонім</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={synonym} onChange={(e) => setSynonym(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Головне зображення</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" value={mainImg} onChange={(e) => setmainImg(e.target.value)} />
          <Link to="/media" target='_blanck'>перейти до медіа</Link>
        </div>
        <div className="mb-3">
          <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div> */}


    </>
  );
};

export default PreOrdersForm;
