import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { roundTheNumber } from '../store/roundTheNumber';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, CFormSelect } from '@coreui/react';
import AxiosInstance from '../Axios';


// import async from 'react-select/dist/declarations/src/async';

const urlApi = '/orders';

const status = [
    { value: 'is_forming', label: 'Формується' },
    { value: 'created', label: 'Створено клієнтом' },
    { value: 'aproved', label: 'Підтверджено' },
    { value: 'comlated', label: 'Виконано' }
]
const paymentMethod = [
    // { value: 'paid', label: 'Оплачено' },
    // { value: 'before_payment', label: 'Перед оплата' },
    { value: 'cash', label: 'Готівка' },
    { value: 'bank', label: 'Банківський переказ' },
    // { value: 'bank', label: 'Мульти оплата' }
]
const curency = [
    // { value: 'paid', label: 'Оплачено' },
    // { value: 'before_payment', label: 'Перед оплата' },
    { value: 'uah', label: 'uah' },
    { value: 'usd', label: 'usd' },
    { value: 'eur', label: 'eur' }
]

const objOrderData = {
    account: '',
    agreement: '',
    manager: '',
    status: '',
    description: '',
    dateOrder: null,
    dateEntered: '',
    curency: '',
    paymentMethod: '',
    sumOrdered: 0,
    curencyRate: 0,
    discountTotal: 0,
    discountPercent: 0,
    shippingAddressStreet: '',
    shippingAddressCity: '',
    shippingAddressBuilding: '',
    shippingAddressCountry: '',
    shippingAddressDate: '',
    synced: false,
};


const ViewOrders = (props) => {
    const data = props.data;
    const [order, setOrder] = useState(objOrderData);
    const [userRole, setUserRole] = useState('');
    const [code, setSetCode] = useState('');

    useEffect(() => {
        if (data != null && data != undefined) {
            setOrder({
                ...order,
                account: (data.account != undefined) ? data.account : '',
                agreement: (data.agreement != undefined) ? data.agreement : '',
                manager: (data.manager != undefined) ? data.manager : '',
                status: (data.status != undefined) ? data.status : '',
                description: (data.description != undefined) ? data.description : '',
                dateOrder: (data.dateOrder != undefined) ? new Date(data.dateOrder).toISOString().slice(0, 10) : '',
                dateEntered: (data.dateEntered !== undefined) ? new Date(data.dateEntered).toISOString().slice(0, 10) : '',
                curency: (data.curency != undefined) ? data.curency : '',
                paymentMethod: (data.paymentMethod != undefined) ? data.paymentMethod : '',
                curencyRate: (data.curencyRate != undefined) ? data.curencyRate : 0,
                discountPercent: (data.discountPercent != undefined) ? data.discountPercent : 0,
                shippingAddressCity: (data.shippingAddressCity != undefined) ? data.shippingAddressCity : '',
                synced: (data.synced != undefined) ? data.synced : false,
            })
            setSetCode(order.code1c);
            let user_role = localStorage.getItem('user_role');
            if(user_role != null){
                setUserRole(user_role);
            }
        }
    }, [data]);

    // Сума замовлення
    const [sum, setSum] = useState("");
    // Заокруглити суму до 2-х знаків після коми
    useEffect(() => {
        setSum(roundTheNumber(props.sumOrder));
    }, [props.sumOrder]);

    const handleSubmit = event => {
        event.preventDefault();
       
       
           
        const response = AxiosInstance.put(`${urlApi}/${data.id}`, {code1c:  code}).then((responce) => {
            console.log(responce);
            props.toastifySuccess('Код збережено!');
        });
           
    }

 
    return (
        <>
            {
                (userRole == 'ROLE_ADMIN')&&
                    <>
                        <CForm onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className='col-8'></div>
                                <div className='col-4 d-flex justify-content-end align-items-center'>
                                    <CFormInput type="text" name='code1c'  value={code} onChange={(e) => {setSetCode( e.target.value)}} placeholder='Код-1с'/>
                                    <CButton type="submit" color="success">Зберегти</CButton>
                                </div>
                            </div>

                        </CForm>
                    
                    </>
            }
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" colSpan={3}>
                                <p className='m-0'>Контрагент: <strong>{order.account.name}</strong></p>
                                <p className='m-0'>Договір: <strong>{order.agreement.name}</strong></p>
                                <p className='m-0'>Менеджер: <strong>{`${order.manager.firstName} ${order.manager.lastName}`}</strong></p>

                            </TableCell>
                            <TableCell align="right">
                                <p className='m-0'>дата створення: <strong>{order.dateEntered}</strong></p>
                                <p className='m-0'>синхронізовано в 1с: <strong>{(order.synced) ? 'Так' : 'Ні'}</strong></p>

                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Статус:</TableCell>
                            <TableCell align="left"><strong>{
                                status.map((v, k) => {
                                    if (order.status == v.value)
                                        return v.label;
                                })
                            }</strong>
                            </TableCell>
                            <TableCell align="right">дата виконання:</TableCell>
                            <TableCell align="left"><strong>{order.dateOrder}</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Адреса:</TableCell>
                            {/* <TableCell align="left" colSpan={3}>{`${data.shippingAddressCountry} ${data.shippingAddressCity} ${data.shippingAddressStreet} ${data.shippingAddressBuilding}`}</TableCell> */}
                            <TableCell align="left" colSpan={3}>{order.shippingAddressCity}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Опис:</TableCell>
                            <TableCell align="left" colSpan={3}><div dangerouslySetInnerHTML={{ __html: order.description }}></div></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Метод оплати:</TableCell>
                            <TableCell align="left" colSpan={3}><div>{
                                paymentMethod.map((v, k) => {
                                    if (order.paymentMethod == v.value)
                                        return v.label;
                                })
                            }</div></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {rows.map((row) => (
                                <TableRow key={row.desc}>
                                    <TableCell>{row.desc}</TableCell>
                                    <TableCell align="right">11111</TableCell>
                                    <TableCell align="right">222222</TableCell>
                                    <TableCell align="right">333333333</TableCell>
                                </TableRow>
                            ))} */}
                        <TableRow>
                            <TableCell rowSpan={4} colSpan={2} />
                            <TableCell >Валюта</TableCell>
                            <TableCell><strong>{order.curencyRate}</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Курс</TableCell>
                            <TableCell><strong>{order.curency}</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >Знижка</TableCell>
                            <TableCell><strong>{order.discountPercent}</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >Сума</TableCell>
                            <TableCell><strong>{sum} грн.</strong></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ViewOrders;