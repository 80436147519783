import React, { useEffect, useRef, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { useNavigate } from "react-router-dom";
import AxiosInstance from '../Axios';
import { transliterate } from 'transliteration';

const VariationsCreateForm = (props) => {
    // console.log("VariationsCreateForm props ", props);
    let data = props.data;

    const [name, setName] = useState((data != undefined) ? data.name : '');
    const [slug, setSlug] = useState((data != undefined) ? data.slug : '');
    // Текст для popup
    const popupTextPostSuccess = "Варіація додана";
    const popupTextPutSuccess = "Варіація оновлена";

    useEffect(() => {
        setName((data != undefined) ? data.name : '');
        setSlug((data != undefined) ? data.slug : '');
    }, [data, props]);

    let navigate = useNavigate();

    const handleSubmit = event => {
        event.preventDefault();
        let slugT;
        if (slug == '')
            slugT = transliterate(name).replace(' ', '');
        else
            slugT = transliterate(slug).replace(' ', '');

        let article = {
            name: name,
            slug: slugT,
            attribute: (props.parent != undefined) ? `/api/attributes/${props.parent}` : props.data.attritute,
        };

        if (data != undefined) {
            AxiosInstance.put('/attribute_items/' + data.id, article).then((response) => {
                // console.log("/attribute_items update response", response)
                // Popup про успішні зміни
                props.toastifySuccess(popupTextPutSuccess);
                navigate("/attributes/variations/update/" + response.data.id);
            })
        } else {
            AxiosInstance.post('/attribute_items', article).then((response) => {
                // console.log("post '/attribute_items', response", response)
                if (response.status == 201) {
                    // Popup про успішні зміни
                    props.toastifySuccess(popupTextPostSuccess);
                    // Закрити модальне вікно створення варіації
                    props.actionShowModal(false);
                }
                return response;
            });
        }
    };

    return (
        <>
            {
                (props.data != undefined) &&
                <h3>Варіація</h3>
            }

            <CForm onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" required />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Синонім</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput2" value={slug} onChange={(e) => setSlug(e.target.value)} autoComplete="off" />
                        </div>
                    </div>
                </div>
                <CButton type="submit" color="success">Зберегти</CButton>
            </CForm>
        </>
    );
};

export default VariationsCreateForm;