import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { roundTheNumber } from 'src/components/store/roundTheNumber';

// Табиця з товарами, які додані до замовлення для orders/update
const ViewOrdersModal = (props) => {
    console.log("props ViewModal", props);

    // Опис для link
    const linkTitle = "Оновити замовлення товару";

    // Функція для відкриття модального вікна оновлення замовлення
    const changeProductUpdate = (product) => {
        // Відкрити вікно для оновлення кількості продукту
        props.setOpenUpdateProduct(true);
        // Передати дані про оновлюваний продукт
        props.setProductForUpdate(product);
    };

    return (
        <>
            <h2 className="my-3 mx-2 orders-tableHeader">Додані товари</h2>
            {
                (props.products.length > 0) ?
                    <TableContainer component={Paper} style={{ marginTop: "13px", maxHeight: 600 }}>
                        <Table sx={{ width: '100%' }} aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="p-1" align="center"><strong>#</strong></TableCell>
                                    {/* <TableCell align="center"><strong>Зображення</strong></TableCell> */}
                                    <TableCell className='p-1' align="center"><strong>Назва</strong></TableCell>
                                    <TableCell className='p-1 orders-ViewModal__PC' align="center">
                                        <div className='orders-ViewModal__TC'>
                                            <strong>Ціна</strong>
                                            <strong>Націнка</strong>
                                            <strong>Собівартість</strong>
                                        </div>
                                    </TableCell>
                                    <TableCell className="p-1 orders-ViewModal__PC" align="center"><strong>К-сть</strong></TableCell>
                                    {/* <TableCell className="p-1 orders-ViewModal__PC" align="center"><strong>О.В.</strong></TableCell> */}
                                    <TableCell className="p-1 orders-ViewModal__PC" align="center"><strong>Сума</strong></TableCell>
                                    {/* <TableCell align="center"><strong>Знижка</strong></TableCell> */}
                                    <TableCell className="p-1" align="center"><strong></strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (props.products.length > 0) &&
                                    props.products.map((value, key) => {
                                        // console.log("value", value);
                                        let i = ++key;
                                        let imgUrl = (value.product.media != undefined && value.product.media.length > 0) ? `${process.env.REACT_APP_SERVER_URL}${value.product.media[0].contentUrl}` : '/product.jpg';
                                        // Ціна заштуку
                                        const price = (value.priceForOne != undefined) ? value.priceForOne : 0;
                                        // Собівартість за штуку
                                        const priceIncome = (value.priceIncome != undefined) ? value.priceIncome : 0;
                                        // Націнка за товар
                                        const productMarkUp = (priceIncome == 0) ? 0 : (price - priceIncome);
                                        // Сума
                                        const sum = price * value.count;
                                        return (
                                            <TableRow key={value.id}>
                                                <TableCell align="center"><strong>{i}</strong></TableCell>
                                                {/* <TableCell align="center">
                                                <Link to={`/products/update/${value.product.id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    <strong><img src={imgUrl} alt="blog-image" style={{ width: "50px" }} /></strong>
                                                </Link>
                                            </TableCell> */}
                                                <TableCell align="center" onClick={() => changeProductUpdate(value)} style={{ cursor: "pointer" }} title={linkTitle}>
                                                    <strong className="orders__linkToOrderProduct">
                                                        {value.product.name}
                                                    </strong>
                                                    <p className='text-muted'><strong>Склад: </strong>{(value.product.storageElement != undefined) ? value.product.storageElement.storage.name : '...'}</p>
                                                    {/* Відображати тільки для мобільної версії */}
                                                    <div className="orders-ViewModal__mobile">
                                                        <span>Ціна {roundTheNumber(price)} | </span>
                                                        <span>Націнка {roundTheNumber(price - priceIncome)} | </span>
                                                        <span>Собівартість {roundTheNumber(priceIncome)} | </span>
                                                        <span>К-сть {value.count} {value.measurementUnit.short_name} | </span>
                                                        {/* <span>О.В. {value.measurementUnit.short_name} | </span> */}
                                                        <span>Сума {roundTheNumber(sum)}</span>
                                                    </div>
                                                    {/* </Link> */}
                                                </TableCell>
                                                <TableCell className='orders-ViewModal__PC' align="center">
                                                    <div className='orders-ViewModal__TC'>
                                                        <strong>{roundTheNumber(price)}</strong>
                                                        <strong>{roundTheNumber(productMarkUp)}</strong>
                                                        <strong>{roundTheNumber(priceIncome)}</strong>
                                                    </div>
                                                </TableCell>
                                                <TableCell className='orders-ViewModal__PC' align="center" onClick={() => changeProductUpdate(value)} title={linkTitle} style={{ cursor: "pointer" }}>
                                                    {/* <strong className="orders__linkToOrderProduct">{value.count} {value.measurementUnit.short_name}</strong> */}
                                                    <strong className="orders__linkToOrderProduct">{value.count} </strong>
                                                </TableCell>
                                                {/* <TableCell className='orders-ViewModal__PC' align="center"><strong>{value.measurementUnit.short_name}</strong></TableCell> */}
                                                <TableCell className='orders-ViewModal__PC' align="center"><strong>{roundTheNumber(sum)}</strong></TableCell>
                                                {/* <TableCell align="center"><strong>{value.discount}</strong></TableCell> */}
                                                <TableCell align="center" style={{ padding: "5px" }}>
                                                    <button title="Видалити товар з замовлення" onClick={() => props.deleteProduct(value.id)}>
                                                        <CIcon icon={cilTrash} customClassName="nav-icon" height={15} />
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer >
                    :
                    <div className="m-3">
                        Ви ще не додали жодного товару
                    </div>
            }
        </>
    );
};

export default ViewOrdersModal;