import React, { useState, useEffect } from 'react';
import { CButton, CForm, CFormCheck } from '@coreui/react';
import AxiosInstance from '../Axios';
import Accordion from 'react-bootstrap/Accordion';

// Компонент атрибутів для продукту
const ProductAttributes = (props) => {
    // console.log("props", props);
    const urlApi_1 = '/attributes';
    const urlApi_2 = '/products';
    const page = 1;

    // Всі атрибути
    const [attributes, setAttributes] = useState([]);
    // Варіації, які вже додані до продукту
    const [attributeItems, setAttributeItems] = useState(props.attributeItems);
    // id варіацій (при старті сторінки), в яких checkbox=true
    const [checkedValue, setValue] = useState([]);
    // Варіації, в яких checkbox=true
    const [checkbox, setCheckbox] = useState([]);
    // id варіацій, які додадуться до продукту і відправляться (PUT) на сервер 
    const [dataResp, setDataResp] = useState([]);
    // Текст для popup
    const popupTextSuccess = "Атрибути збережено";

    // Всі атрибути при завантаженні сторінки
    useEffect(() => {
        AxiosInstance.get(`${urlApi_1}?main=true&page=${page}`).then((response) => {
            // console.log("getAttributse response", response)
            setAttributes(response.data['hydra:member']);
        });
    }, []);

    // Формування data_response
    useEffect(() => {
        let newDataResp = [];
        if (checkbox.length > 0) {
            // console.log("checkbox.length > 0", checkbox.length);
            checkbox.map((el) => newDataResp.push(`api/attribute_items/${el.id}`));
        } else {
            // console.log("checkbox.length = 0", checkbox.length);
            newDataResp = {};
        }
        // console.log("newDataResp", newDataResp);
        // Додати елемент до масива, який піде на сервер для оновлення варіацій для продукту
        setDataResp(newDataResp);
    }, [checkbox]);

    // Відправлення форми оновлення варіацій продукту
    const handleSubmit = event => {
        event.preventDefault();
        const data_response = {
            attributeItems: dataResp
        }
        if (props.productId != undefined) {
            const response = AxiosInstance.put(`${urlApi_2}/${props.productId}`, data_response).then((response) => {
                if (response.status === 200) {
                    // Popup про успішні зміни
                    props.toastifySuccess(popupTextSuccess);
                    // console.log("put response", response);
                }
            });
        }
    };

    // При старті додати варіації до продукту
    useEffect(() => {
        // console.log("111 attributeItems", attributeItems)
        if (attributeItems.length > 0) {
            // Масив з id для проставляння checkbox=true
            let parseArr = [];
            // Масив з елементами, в яких el.checked = "true"
            let parseArrEl = [];
            // Проходимо по атрибутах товару
            attributeItems.forEach(el => {
                // Ставимо позначку, що цей атрибут чекнутий
                el["checked"] = true;
                parseArrEl.push(el);
                parseArr.push(el.id);
            })
            // Додаємо в масив з варіаціями, в яких checkbox=true
            setCheckbox(parseArrEl);
            // Додаємо в масив з id, в яких checkbox=true
            setValue(parseArr);
        };
    }, [attributeItems]);

    // Функція для зміни значення checked
    const sheckedChange = (el, defaultValue) => {
        // console.log(" sheckedChange checkbox", checkbox);
        // Якщо немало значень (el.checked), берем по дефолту (при старті сторінки прийшли варіації з сервера defaultValue=true, інші defaultValue=false)
        if (el.checked === undefined) {
            // Ставимо позначку, що цей атрибут чекнутий (el.checked = true) / нечекнутий (el.checked = false)
            el["checked"] = !defaultValue;
            // Якщо defaultValue = true --> el.checked = false
            if (defaultValue) {
                // Видалити елемент з масива з варіаціями, в яких checkbox=true
                deleteOrder(el.id);
            } else {
                // Додати елемент в масив з варіаціями, в яких checkbox=true
                addToOrder(el);
            }
        } else if (el.checked === true) {
            // Змінити значення поля checked на протилежне
            el["checked"] = !el.checked;
            // Видалити елемент з масива з варіаціями, в яких checkbox=true
            deleteOrder(el.id);
        } else {
            // Змінити значення поля checked на протилежне
            el["checked"] = !el.checked;
            // console.log("sheckedChange deleteOrder el", el);
            // Додати елемент в масив з варіаціями, в яких checkbox=true
            addToOrder(el);
        }
    }

    // Додавання варіацій до масиву + перевірка чи вже додано
    const addToOrder = (item) => {
        // console.log("addToOrder_1 item", item);
        // чи товар вже є в корзині
        let isInArray = false;
        // Перевірка чи товар вже є в корзині
        if (checkbox.length > 0) {
            checkbox.forEach(el => {
                // console.log("addToOrder_1");
                if (el.id === item.id) {
                    isInArray = true;
                }
            })
        }
        // Якщо масив з варіаціями, в яких checkbox=true порожній і товара немає в корзині, то додати
        if (checkbox === undefined && !isInArray) {
            // console.log("addToOrder_2", item);
            // Додати елемент в масив з варіаціями, в яких checkbox=true
            setCheckbox([item]);
        } else if (!isInArray) {
            // console.log("addToOrder_3", item);
            // Додати елемент в масив з варіаціями, в яких checkbox=true
            setCheckbox([...checkbox, item]);
        }
    }

    // Видалення товарів з корзини
    const deleteOrder = (id) => {
        // console.log("deleteOrder item", id);
        setCheckbox(checkbox => checkbox.filter(el => el.id !== id));
    }

    return (
        <>
            <h3>Атрибути</h3>
            <CForm onSubmit={handleSubmit}>
                <Accordion>
                    {
                        (attributes.length > 0) &&
                        attributes.map((attribute, index) => {
                            // Атрибути
                            return (
                                <Accordion.Item key={attribute.id} eventKey={index}>
                                    <Accordion.Header>{attribute.name}</Accordion.Header>
                                    <Accordion.Body>
                                        {
                                            (attribute.attributeItems.length > 0) &&
                                            attribute.attributeItems.map(variation => {
                                                // Варіації
                                                // При старті сторінки проставити checked для варіацій, які додані до продукту 
                                                let d = (checkedValue.includes(variation.id)) ? true : false;
                                                return (
                                                    <div className="mb-2" key={variation.id}>
                                                        <CFormCheck label={variation.name} defaultChecked={d} onChange={() => sheckedChange(variation, d)} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                    }
                </Accordion>
                <CButton type="submit" className="my-3" color="success">Зберегти атрибут</CButton>
            </CForm>
        </>
    )
}

export default ProductAttributes;