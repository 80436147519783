import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';

const urlApi = '/fees';
const urlRouter = '/fees';

export async function LoaderFeesUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
};

const FeesForm = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [sum, setSum] = useState((data != undefined) ? data.sum : '');
  const [percent, setPercent] = useState((data != undefined) ? data.percent : '');
  const [status, setStatus] = useState((data != undefined) ? data.status : '');
  const [type, setType] = useState((data != undefined) ? data.type : '');
  const editorRef = useRef(null);
  // Відобразити "Суму" або "Відсоток"
  const [choice, setChoice] = useState("-- Виберіть варіант --");
  // Значення, яке заповниться в "Суму" або "Відсоток"
  const [choiceValue, setChoiceValue] = useState("");

  // При зміні "Сума" або "Відсоток" обнулити значення, яке заповниться в "Суму" або "Відсоток"
  useEffect(() => {
    // Обнулити
    setChoiceValue("");
  }, [choice]);

  // Функція для заповнення одного з полів "Сума"/"Відсоток", а інше поле обнулить
  useEffect(() => {
    if (choice === "sum") {
      setSum(choiceValue);
      setPercent(0);
    };
    if (choice === "percent") {
      setPercent(choiceValue);
      setSum(0);
    };
  }, [choiceValue]);

  // При редагуванні "Fees" заповнити одне з полів "Сума"/"Відсоток"
  useEffect(() => {
    if (data != undefined) {
      // if (data.percent != undefined && data.percent === 0) {
      if (data.percent === 0) {
        setChoice("sum");
        // Необхідна затримка в часі, щоб інший useEffect не перетер choiceValue (він спрацьовує при зміні choice "Сума"/"Відсоток")
        setTimeout(() => {
          setChoiceValue(data.sum);
        }, 500);
      };
      if (data.sum === 0) {
        setChoice("percent");
        // Необхідна затримка в часі, щоб інший useEffect не перетер choiceValue (він спрацьовує при зміні choice "Сума"/"Відсоток")
        setTimeout(() => {
          setChoiceValue(data.percent);
        }, 500);
      };
    };
  }, [data]);

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      const data_response = {
        name: name,
        sum: parseFloat(sum),
        percent: parseFloat(percent),
        status: status,
        type: type,
        active: 'on',
      };
      if (data != undefined) {
        const response = AxiosInstance.put(`${urlApi}/${data.id}`, data_response).then((response) => {
          if (response.status === 200)
            return navigate(urlRouter);
        });
      } else {
        const response = AxiosInstance.post(`${urlApi}`, data_response).then((response) => {
          if (response.status === 201)
            return navigate(urlRouter);
        });
      }
    }
  };

  return (
    <>
      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
        </div>

        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Сума/Відсоток</CFormLabel>
          <select className="form-select" value={choice} onChange={(e) => setChoice(e.target.value)} >
            <option disabled>-- Виберіть варіант --</option>
            <option value="sum">Сума</option>
            <option value="percent">Відсоток</option>
          </select>
          {
            // Відображати Input тільки при виборі "Сума"/"Відсоток"
            (choice !== "-- Виберіть варіант --") &&
            <CFormInput className="mt-3" type="text" id="exampleFormControlInput2" value={choiceValue} onChange={(e) => setChoiceValue(e.target.value)} autoComplete="off" />
          }
        </div>

        {/* <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Сума</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={sum} onChange={(e) => setSum(e.target.value)} autoComplete="off" />
        </div> */}
        {/* <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Відсоток</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" value={percent} onChange={(e) => setPercent(e.target.value)} autoComplete="off" />
        </div> */}
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Статус</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput4" value={status} onChange={(e) => setStatus(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Тип</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput5" value={type} onChange={(e) => setType(e.target.value)} autoComplete="off" />
        </div>

        <div className="mb-3">
          <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div>

        <CButton type="submit" color="success">Зберегти</CButton>
        <Link to={urlRouter} className="ms-3"><CButton type="submit" color="secondary">Відміна</CButton></Link>
      </CForm>
    </>
  );
};

export default FeesForm;